import "./index.css";

export default (props) => (
  <div
    className={`input-container ${props.error ? "error" : ""}`}
    style={{
      maxWidth: props.width,
    }}
  >
    <div className="input-label">
      {props.label}
      {props.required && " *"}
    </div>
    <input
      type={props.type}
      className="input"
      value={props.value}
      onChange={props.onChange}
      placeholder={props.label}
      name={props.name}
    />
  </div>
);

import "./index.css";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { logo } from "../../data";
import { List, X } from "@phosphor-icons/react";

const items = [
  {
    link: "/",
    section: "#price-calculator",
    title: "Fiyat Hesaplama",
  },
  {
    link: "/",
    section: "#about",
    title: "Hakkımızda",
  },
  {
    link: "/",
    section: "#services",
    title: "Hizmetlerimiz",
  },
  {
    link: "/",
    section: "#partners",
    title: "Servislerimiz",
  },
  {
    link: "/contact",
    title: "İletişim",
  },
];

export default (props) => {
  const location = useLocation();
  const navigate = useNavigate();

  const headerRef = useRef();
  const [headerHeight, setHeaderHeight] = useState(0);

  useEffect(() => {
    setHeaderHeight(headerRef.current.clientHeight);
  }, []);

  const [currentSection, setCurrentSection] = useState("price-calculator");
  const [isScrolled, setIsScrolled] = useState(false);

  const sections = document.querySelectorAll(".section");
  const headerItems = document.querySelectorAll(".header-items");

  window.addEventListener("scroll", () => {
    sections.forEach((section) => {
      if (window.scrollY >= section.offsetTop - 340) {
        setCurrentSection(section.id);
      }
    });

    headerItems.forEach((headerItem) => {
      if (headerItem.href?.includes(currentSection)) {
        document.querySelector(".focused").classList.remove("focused");
        headerItem.classList.add("focused");
      }
    });

    setIsScrolled(window.pageYOffset === 0 ? false : true);
    return () => (window.onscroll = null);
  });

  const [menuIsOpen, setMenuIsOpen] = useState(false);

  return (
    <>
      <div
        style={{
          paddingTop: !props.transparent && headerHeight,
        }}
      />
      <div
        ref={headerRef}
        className={`header-wrapper ${props.transparent ? "transparent" : ""} ${
          isScrolled ? "scrolled" : ""
        } ${menuIsOpen ? "menu-is-open" : ""}`}
      >
        <div className="header container">
          <a href="/" className="header-logo">
            <img src={logo} alt="" className="header-logo-image" />
          </a>
          <div className="header-items">
            {items.map((item, index) => (
              <a
                onClick={() => navigate(item.link)}
                href={item.section ? item.section : ""}
                className={`header-item ${
                  location.pathname === "/"
                    ? item.section?.includes(currentSection)
                      ? "focused"
                      : ""
                    : location.pathname === item.link
                    ? "focused"
                    : ""
                }`}
                key={index}
              >
                {item.title}
              </a>
            ))}
          </div>
          <div
            className="header-button"
            onClick={() => setMenuIsOpen(!menuIsOpen)}
          >
            {menuIsOpen ? <X /> : <List />}
          </div>
        </div>
      </div>
    </>
  );
};

import "./index.css";
import {
  EnvelopeSimple,
  MapPin,
  Phone,
  WhatsappLogo,
} from "@phosphor-icons/react";

const links = [
  {
    icon: MapPin,
    title: "Bağlar, 36. sokak no:3A, 34209 Bağcılar/İstanbul",
    link: "https://maps.app.goo.gl/S4xf6WBjZTASV6uP6",
  },
  {
    icon: Phone,
    title: "+90 850 220 45 34",
    link: "tel:+90 850 220 45 34",
  },
  {
    icon: WhatsappLogo,
    title: "+90 533 655 60 82",
    link: "https://api.whatsapp.com/send?phone=905336556082",
  },
  {
    icon: EnvelopeSimple,
    title: "info@kargomekspres.com",
    link: "mailto:info@kargomekspres.com",
  },
];

export default (props) => (
  <div className="contact-links-container">
    {props.title && <div className="form-title">İletişime Geçin</div>}
    <div className="contact-links">
      {links.map((link, index) => (
        <a
          href={link.link}
          target="_blank"
          className="contact-link"
          key={index}
        >
          <div className="contact-link-icon">
            <link.icon />
          </div>
          <div className="contact-link-title">{link.title}</div>
        </a>
      ))}
    </div>
  </div>
);

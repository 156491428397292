import "./index.css";
import { Input, MessageBox, Layout, ContactLinks } from "../../components";
import { PaperPlaneRight } from "@phosphor-icons/react";
import { useRef, useState } from "react";
import emailjs from "@emailjs/browser";

export default () => {
  const form = useRef();

  const [name, setName] = useState("");
  const [nameError, setNameError] = useState(false);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [message, setMessage] = useState("");
  const [messageError, setMessageError] = useState(false);

  const inputs = [
    {
      type: "text",
      label: "İsim",
      name: "user_name",
      state: name,
      setState: setName,
      errorState: nameError,
      setErrorState: setNameError,
      required: true,
    },
    {
      type: "email",
      label: "E-Posta",
      name: "user_email",
      state: email,
      setState: setEmail,
      errorState: emailError,
      setErrorState: setEmailError,
      required: true,
    },
    {
      type: "tel",
      label: "Telefon",
      name: "user_tel",
      required: false,
    },
  ];

  return (
    <Layout title="İletişim" className="contact section-container">
      <ContactLinks title />
      <form
        ref={form}
        className="contact-form"
        onSubmit={(event) => {
          event.preventDefault();

          setNameError(name === "");
          setEmailError(email === "");
          setMessageError(message === "");

          if (name && email && message) {
            emailjs
              .sendForm(
                "service_7fp7zk2",
                "template_sbejvli",
                form.current,
                "9UGdv5BbNft-du6KD"
              )
              .then(
                (result) => {
                  alert("Mesaj gönderildi.");
                  console.log(result.text);
                  console.log("message sent");
                },
                (error) => {
                  console.log(error.text);
                }
              );
          }
        }}
      >
        <div className="form-title">Bize Yazın</div>
        <div className="contact-form-inputs">
          {inputs.map((input, index) => (
            <Input
              type={input.type}
              label={input.label}
              width="100%"
              key={index}
              name={input.name}
              value={input.state}
              onChange={(event) => input.setState(event.target.value)}
              error={input.errorState}
              required={input.required}
            />
          ))}
        </div>
        <MessageBox
          label="Mesaj"
          name="message"
          required
          value={message}
          onChange={(event) => setMessage(event.target.value)}
          error={messageError}
        />
        <button type="submit" className="contact-form-button">
          <div className="contact-form-button-icon">
            <PaperPlaneRight />
          </div>
          <div className="contact-form-button-title">Gönder</div>
        </button>
      </form>
    </Layout>
  );
};

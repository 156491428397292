import "./index.css";
import { Helmet } from "react-helmet";
import { ContactButtons, Header, Footer } from "..";
import { brand, logo } from "../../data";

export default (props) => (
  <>
    <Helmet>
      <title>{`${brand} - ${props.title}`}</title>
      <link rel="icon" type="image/x-icon" href={logo} />
    </Helmet>
    <ContactButtons transparent={props.transparentHeader} />
    <div className="layout-container">
      <Header transparent={props.transparentHeader} />
      <div className={`layout ${props.className}`}>{props.children}</div>
      <Footer />
    </div>
  </>
);

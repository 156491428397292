import "./index.css";
import { services } from "../../data";

export default () => (
  <div className="section section-container" id="services">
    <div className="section-title">Hizmetlerimiz</div>
    <div className="services">
      {services.map((item, index) => (
        <div className="service" key={index}>
          <img src={item.image} alt="" className="service-image" />
          <div className="service-info">
            <div className="service-title">{item.title}</div>
            <div className="service-description">{item.description}</div>
          </div>
        </div>
      ))}
    </div>
  </div>
);

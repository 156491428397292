import { BrowserRouter, Routes, Route } from "react-router-dom";
import { routes } from "./data";

export default () => (
  <BrowserRouter>
    <Routes>
      {routes.map((route, index) => (
        <Route path={route.path} element={<route.element />} key={index} />
      ))}
    </Routes>
  </BrowserRouter>
);

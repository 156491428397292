import "./index.css";
import { ContactLinks, SocialLinks } from "..";
import { useNavigate } from "react-router-dom";

const columns = [
  {
    title: "Kurumsal",
    list: [
      {
        link: "/",
        section: "#about",
        title: "Hakkımızda",
      },
      {
        link: "/",
        section: "#services",
        title: "Hizmetlerimiz",
      },
      {
        link: "/contact",
        title: "İletişim",
      },
    ],
  },
  {
    title: "Sayfalar",
    list: [
      {
        link: "/",
        section: "#partners",
        title: "Servislerimiz",
      },
      {
        link: "/cookie-policy",
        title: "Çerez Politikası",
      },
      {
        link: "/confidentiality-agreement-and-illumination-text",
        title: "Gizlilik Sözleşmesi ve Aydınlatma Metni",
      },
    ],
  },
];

export default () => {
  const navigate = useNavigate();

  return (
    <div className="container">
      <div className="footer">
        <div className="footer-columns">
          <div className="footer-column">
            <div className="footer-column-title">Sosyal Medya</div>
            <SocialLinks />
          </div>
          <div className="footer-column">
            <div className="footer-column-title">İletişim</div>
            <ContactLinks />
          </div>
          {columns.map((column, index) => (
            <div className="footer-column" key={index}>
              <div className="footer-column-title">{column.title}</div>
              <div className="footer-column-list">
                {column.list.map((item, index) => (
                  <a
                    onClick={() => navigate(item.link)}
                    href={item.section ? item.section : ""}
                    className="footer-column-list-item"
                    key={index}
                  >
                    {item.title}
                  </a>
                ))}
              </div>
            </div>
          ))}
        </div>
        <div className="footer-copyright">&copy; 2023 - kargomekspres.com</div>
      </div>
    </div>
  );
};

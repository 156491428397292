import "./index.css";
import { Layout } from "../../components";
import { ArrowRight } from "@phosphor-icons/react";

export default () => (
  <Layout
    header
    footer
    title="Sayfa Bulunamadı"
    className="not-found container"
  >
    <div className="not-found-title">Sayfa Bulunamadı</div>
    <div className="not-found-description">Aradığınız sayfayı bulamıyoruz.</div>
    <a href="/" className="not-found-button">
      <div className="not-found-button-title">Anasayfaya Dön</div>
      <div className="not-found-button-icon">
        <ArrowRight />
      </div>
    </a>
  </Layout>
);

import "./index.css";
import { FacebookLogo, InstagramLogo } from "@phosphor-icons/react";

const links = [
  {
    icon: InstagramLogo,
    title: "Instagram",
    link: "https://www.instagram.com/kargomekspres/",
  },
  {
    icon: FacebookLogo,
    title: "Facebook",
    link: "https://www.facebook.com/profile.php?id=100089295090132",
  },
];

export default () => (
  <div className="social-links">
    {links.map((link, index) => (
      <a href={link.link} target="_blank" className="social-link" key={index}>
        <div className="social-link-icon">
          <link.icon />
        </div>
        <div className="social-link-title">{link.title}</div>
      </a>
    ))}
  </div>
);

import { Layout } from "../components";
import { confidentialityAgreementAndIlluminationText } from "../data";

export default () => (
  <Layout title="Gizlilik Sözleşmesi" className="section section-container">
    <div className="section-title">Gizlilik Sözleşmesi ve Aydınlatma Metni</div>
    <div className="section-description">
      {confidentialityAgreementAndIlluminationText}
    </div>
  </Layout>
);

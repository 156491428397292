import "./index.css";
import { useState } from "react";
import { Phone, WhatsappLogo } from "@phosphor-icons/react";

const buttons = [
  {
    icon: Phone,
    title: "+90 850 220 45 34",
    link: "tel:+90 850 220 45 34",
  },
  {
    icon: WhatsappLogo,
    title: "+90 533 655 60 82",
    link: "https://api.whatsapp.com/send?phone=905336556082",
    filled: true,
  },
];

export default (props) => {
  const [isScrolled, setIsScrolled] = useState(false);

  window.addEventListener("scroll", () => {
    setIsScrolled(window.pageYOffset === 0 ? false : true);
    return () => (window.onscroll = null);
  });

  return (
    <div
      className={`contact-buttons ${props.transparent ? "transparent" : ""} ${
        isScrolled ? "scrolled" : ""
      }`}
    >
      {buttons.map((button, index) => (
        <a
          href={button.link}
          target="_blank"
          className={`contact-button ${button.filled ? "filled" : ""}`}
          key={index}
        >
          <button.icon />
          <div className="contact-button-title">{button.title}</div>
        </a>
      ))}
    </div>
  );
};

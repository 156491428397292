import "./index.css";
import { useEffect, useState } from "react";
import { Input, Selector } from "..";
import { prices, regions } from "../../data";
import { Info, Phone, WhatsappLogo } from "@phosphor-icons/react";

const links = [
  {
    icon: Phone,
    title: "+90 850 220 45 34",
    link: "tel:+90 850 220 45 34",
  },
  {
    icon: WhatsappLogo,
    title: "+90 533 655 60 82",
    link: "https://api.whatsapp.com/send?phone=905336556082",
  },
];

export default () => {
  const [destination, setDestination] = useState();
  const [width, setWidth] = useState();
  const [size, setSize] = useState();
  const [height, setHeight] = useState();
  const [weight, setWeight] = useState();
  const [result, setResult] = useState();

  const inputs = [
    {
      label: "En (CM)",
      state: width,
      setState: setWidth,
    },
    {
      label: "Boy (CM)",
      state: size,
      setState: setSize,
    },
    {
      label: "Yükseklik (CM)",
      state: height,
      setState: setHeight,
    },
    {
      label: "Ağırlık (KG)",
      state: weight,
      setState: setWeight,
    },
  ];

  useEffect(() => {
    if (destination && width && size && height && weight) {
      const currentWeight = Math.max((width * size * height) / 5000, weight);

      const currentPrice = prices
        .find((price) => {
          const [minWeight, maxWeight] = price.weight;

          if (maxWeight) {
            return minWeight <= currentWeight && maxWeight >= currentWeight;
          } else {
            return minWeight <= currentWeight;
          }
        })
        .regions.find(
          (region) =>
            region.id === regions.find((item) => item.name === destination).id
        ).price;

      setResult(
        (currentWeight >= 31 ? currentPrice * currentWeight : currentPrice)
          .toFixed(1)
          .split(".0")
      );
    } else {
      setResult();
    }
  }, [destination, width, size, height, weight]);

  return (
    <div className="price-calculator section" id="price-calculator">
      <div className="form-title">Fiyat Hesaplama</div>
      <div className="price-calculator-info">
        <div className="price-calculator-item">
          <div className="price-calculator-item-label">Nereden</div>
          <div className="price-calculator-item-value">Türkiye</div>
        </div>
        <Selector
          label="Nereye"
          options={regions.map((region) => region.name)}
          searchBar
          setState={setDestination}
        />
        {inputs.map((input, index) => (
          <Input
            type="number"
            label={input.label}
            min={0}
            width="100%"
            value={input.state}
            onChange={(event) => input.setState(event.target.value)}
            key={index}
          />
        ))}
      </div>
      {result && (
        <div className="price-calculator-result">
          <div className="price-calculator-result-label">Toplam</div>
          <div className="price-calculator-result-value">€{result}</div>
        </div>
      )}
      <div className="price-calculator-contact-links">
        {links.map((link, index) => (
          <a
            href={link.link}
            target="_blank"
            className="price-calculator-contact-link"
            key={index}
          >
            <div className="price-calculator-contact-link-icon">
              <link.icon />
            </div>
            <div className="price-calculator-contact-link-title">
              {link.title}
            </div>
          </a>
        ))}
      </div>
      <div className="price-calculator-details">
        <div className="price-calculator-details-icon">
          <Info />
        </div>
        <div className="price-calculator-details-title">
          30 kilo üzeri kampanyalı fiyatlar için bizi arayın.
        </div>
      </div>
    </div>
  );
};

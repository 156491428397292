import "./index.css";

export default (props) => (
  <div className={`message-box ${props.error ? "error" : ""}`}>
    <div className="message-box-label">
      {props.label}
      {props.required && " *"}
    </div>
    <textarea
      rows={1}
      value={props.value}
      onChange={props.onChange}
      className="message-box-input"
      placeholder=""
      name={props.name}
    />
  </div>
);

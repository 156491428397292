import "./index.css";
import { useRef, useEffect, useState } from "react";
import { CaretUpDown, MagnifyingGlass, XCircle } from "@phosphor-icons/react";

export default (props) => {
  const inputRef = useRef();

  const [isShown, setIsShown] = useState(false);
  const [value, setValue] = useState(false);

  useEffect(() => {
    props.setState(value);

    setIsShown(false);
  }, [value]);

  const [filterValue, setFilterValue] = useState("");
  const [filteredOptions, setFilteredOptions] = useState(props.options);

  return (
    <>
      <div
        className={`selector ${value ? "selected" : ""}`}
        onClick={() => setIsShown(true)}
      >
        <div className="selector-info">
          <div className="selector-label">{props.label}</div>
          <div className="selector-value">{value}</div>
        </div>
        <div className="selector-icon">
          <CaretUpDown />
        </div>
        <div className={`selector-options ${isShown ? "shown" : ""}`}>
          {props.searchBar && (
            <div className="selector-search-bar">
              <div className="selector-search-bar-icon">
                <MagnifyingGlass />
              </div>
              <input
                type="text"
                placeholder="Ara"
                value={filterValue}
                onChange={(event) => {
                  setFilterValue(event.target.value);

                  setFilteredOptions(
                    props.options.filter((option) =>
                      option
                        .toLowerCase()
                        .includes(event.target.value.toLowerCase())
                    )
                  );
                }}
                className="selector-search-bar-input"
                ref={inputRef}
              />
              {filterValue !== "" && (
                <div
                  className="selector-search-bar-icon"
                  onClick={() => {
                    setFilterValue("");
                    setFilteredOptions(props.options);

                    inputRef.current.focus();
                  }}
                >
                  <XCircle weight="fill" />
                </div>
              )}
            </div>
          )}
          {filteredOptions.map((option, index) => (
            <div
              className="selector-option"
              key={index}
              onClick={() => {
                setValue(option);
              }}
            >
              {option}
            </div>
          ))}
        </div>
      </div>
      {isShown && (
        <div className="selector-overlay" onClick={() => setIsShown(false)} />
      )}
    </>
  );
};

import "./index.css";

export default (props) => (
  <div
    className="banner"
    style={{
      backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.75)), url(${props.backgroundImage})`,
    }}
  >
    <div className="banner-container">{props.children}</div>
  </div>
);

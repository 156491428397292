import { homeBanner } from "../data";
import {
  Layout,
  Banner,
  PriceCalculator,
  About,
  Services,
  Partners,
} from "../components";

export default () => (
  <Layout title="Anasayfa" transparentHeader>
    <Banner backgroundImage={homeBanner}>
      <PriceCalculator />
    </Banner>
    <About />
    <Services />
    <Partners />
  </Layout>
);

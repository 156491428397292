import "./index.css";
import { partners } from "../../data";

export default () => (
  <div className="section section-container" id="partners">
    <div className="section-title">Servislerimiz</div>
    <div className="partners">
      {partners.map((image, index) => (
        <img src={image} alt="" className="partner" key={index} />
      ))}
    </div>
  </div>
);
